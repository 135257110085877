import React from 'react';
import * as S from './form.styled';
import useForm from './useForm';

const Form = ({ className }) => {
  const {
    handleChange,
    values,
    handleSubmit,
    validationErrors,
    mailSent,
    serverError,
  } = useForm();
  return (
    <S.Form className={className} mailSent={mailSent}>
      <form action="#">
        <input
          name="ogrationspam"
          type="text"
          placeholder="Enter your name"
          value={values.ogrationspam}
          onChange={handleChange}
          style={{ visibility: 'hidden', display: 'none', opacity: '0' }}
        />
        <S.FormGroup>
          <label htmlFor="name">Name</label>
          <input
            name="name"
            type="text"
            placeholder="Enter your name"
            value={values.name}
            onChange={handleChange}
          />
          {validationErrors.name && <p>{validationErrors.name}</p>}
        </S.FormGroup>
        <S.FormGroup>
          <label htmlFor="email">Email</label>
          <input
            name="email"
            type="text"
            placeholder="Enter your email"
            value={values.email}
            onChange={handleChange}
          />
          {validationErrors.email && <p>{validationErrors.email}</p>}
        </S.FormGroup>
        <S.FormGroup>
          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            type="textarea"
            placeholder="Enter your Message"
            value={values.message}
            onChange={handleChange}
          />
          {validationErrors.message && <p>{validationErrors.message}</p>}
        </S.FormGroup>
        {/* <S.CheckBoxGroup>
          <label htmlFor="message">
            I accept <a href="/privacy-policy"> Privacy Policy</a>
          </label>
          <input
            name="privacy"
            id="privacy"
            type="checkbox"
            value={values.privacy}
            onChange={handleChange}
          />
          <span className="checkmark" id="checkmark"></span>
          {validationErrors.privacy && <p>{validationErrors.privacy}</p>}
        </S.CheckBoxGroup> */}
      </form>
      <button type="submit" onClick={handleSubmit} value="submit">
        Submit
      </button>
      <div className="submit-msg">
        {mailSent && <div className="success">Success: Message Sent.</div>}
        {serverError && (
          <div className="error">Error: Please check your input.</div>
        )}
      </div>
    </S.Form>
  );
};

export default Form;
