import { useState, useEffect } from 'react';
import axios from 'axios';
import validateInfo from './validateInfo';

const useForm = () => {
  const [values, setValues] = useState({
    ogrationspam: '',
    name: '',
    email: '',
    message: '',
    privacy: true,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [validation, setValidation] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    setValidation(
      validateInfo(values) &&
        Object.keys(validateInfo(values)).length === 0 &&
        validateInfo(values).constructor === Object
    );
  }, [values]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'privacy') {
      setValues({ ...values, [name]: checked });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation && values.ogrationspam === '') {
      axios({
        method: 'post',
        url: 'https://www.afrik-us.com/api/contact/index.php', //change this to correct endpoint
        headers: { 'content-type': 'application/json' },
        data: values,
      })
        .then((result) => {
          if (result.data.sent) {
            setMailSent(result.data.sent);
            setServerError(false);
            setValues({ ...values, name: '', email: '', message: '' });
          } else {
            setServerError(true);
          }
        })
        .catch((error) => setServerError(error.message));
    }
    setValidationErrors(validateInfo(values));
  };

  return {
    handleChange,
    values,
    handleSubmit,
    validationErrors,
    mailSent,
    serverError,
  };
};

export default useForm;
