import styled from 'styled-components';
import Container from '../../layout/Container';
import media from '../../layout/mediaQuery';

export const Contact = styled.div`
  background-color: var(--color-secondary);
  padding: 10rem 0;
`;

export const ContainerWrapper = styled(Container)`
  > * {
    width: calc(100% / 2);
  }

  > * + * {
    margin: 0 0 0 2rem;
  }

  ${media.tabPort`
    flex-direction: column;
    > * + * {
        margin: 4rem 0 0 0;
    }
    > * {
        width: 100%;
    }
 `}
`;

export const Contents = styled.div`
  /* padding: 6rem 3rem 3rem 3rem; */

  > * + * {
    margin-top: 2rem;
  }
`;

export const Image = styled.div``;
