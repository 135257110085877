import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout/Layout';
import HeroInner from '../components/sections/HeroInner/HeroInner.component';
import Contact from '../components/sections/Contact/Contact.component';
import MainPitch from '../components/sections/MainPitch/MainPitch.component';

const ContactPage = ({ data }) => {
  const { title, image, contacts, offices, seo } =
    data.markdownRemark.frontmatter;
  const { seoTitle, seoDescription, browserTitle } = seo;
  return (
    <Layout>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <HeroInner image={image} text={title} />
      <Contact data={contacts} />
      <MainPitch data={offices} bgColor />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        contacts {
          heading
          tagline
          description
          image1 {
            imageAlt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        offices {
          icon
          title
          description
          linkLabel
          linkType
          linkURL
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
  }
`;
