import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as S from './contact.styled';
import Heading from '../../elements/Heading/Heading.component';
import Form from '../../elements/Form/Form.component';
// import Form from '../../elements/form-A';

const Contact = ({ data }) => {
  const { heading, tagline, description, image1 } = data;
  const img = getImage(image1.image);
  return (
    <S.Contact id="contact">
      <S.ContainerWrapper>
        <GatsbyImage
          image={img}
          alt={image1.imageAlt}
          className="gatsby-img u-max-width-70"
        />
        <S.Contents>
          <Heading
            className="u-max-width-70"
            tagline={tagline}
            title={heading}
            description={description}
            inverseColor
          />
          <Form className="u-max-width-70" />
        </S.Contents>
      </S.ContainerWrapper>
    </S.Contact>
  );
};

export default Contact;
