import styled from 'styled-components';
import Container from '../../layout/Container';
import Button from '../../elements/Button/Button.component';

export const Form = styled.div`
  button {
    font-family: inherit;
    font-size: inherit;
    padding: 1.5rem 6rem;
    margin: 4rem auto 0 auto;
    display: block;
    border-radius: 2px;
    background: var(--color-accent);
    outline: none;
    border: none;
    color: var(--color-grey-2);

    &:hover {
      cursor: pointer;
      background: var(--color-grey-2);
      color: var(--color-secondary);
      transition: all 0.4s ease-out;
    }
  }
  span {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 80%;
    text-align: center;
  }
  .submit-msg {
    display: block;
    background: ${({ mailSent }) => (mailSent ? '#55bc1e' : '#cc0000')};
    color: var(--color-grey-2);
    margin-top: 2rem;
    padding: 0 2rem;
    text-align: center;
  }
`;

export const ContainerWrapper = styled(Container)``;

export const Contents = styled.div``;

export const FormGroup = styled.div`
  margin-bottom: 2rem;
  /* width: 80%; */

  label {
    display: inline-block;
    margin-bottom: 0.6rem;
    color: #fff;
  }

  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
    display: block;
    padding-left: 1rem;
    outline: none;
    border-radius: 2px;
    height: 5rem;
    width: 100%;
    border: none;

    &::placeholder {
      color: var(--color-grey-5);
    }
  }

  textarea {
    height: 12rem;
    padding-top: 2rem;
  }

  p {
    font-size: 1.4rem;
    margin-top: 0.5rem;
    color: #cc0000;
  }
`;

export const CheckBoxGroup = styled.div`
  color: var(--color-grey-2);
  position: relative;

  input {
    position: absolute;
    opacity: 1;
    cursor: pointer;
    top: 5px;
    left: 0;
    height: 2rem;
    width: 2rem;
    opacity: 0;
    z-index: 1;

    &:checked {
      ~ .checkmark {
        background-color: #2196f3;
      }

      ~ .checkmark::after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: var(--color-grey-2);

    &::after {
      content: '';
      position: absolute;
      left: 7px;
      top: 0;
      width: 8px;
      height: 14px;
      border: solid var(--color-grey-2);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      display: none;
    }
  }

  label {
    margin-left: 3.5rem;
  }

  p {
    font-size: 1.4rem;
    margin-top: 0.5rem;
    color: #cc0000;
  }
`;

export const SubmitButton = styled(Button)``;
